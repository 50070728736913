
import { inject, provide, onBeforeUnmount, onMounted } from 'vue';
/**
 * Composable that provides nesting functionalities for the given key
 * Must be called in before returning from setup
 */
export default function useNestedStack(options: {
    injectinoKey: Symbol | string, // Required string or symbol to be used with inject/provide
    nestedValue?: any, // Optional value when defined will be attached to the nest on mount, otherwise need to call add function manually
    componentUID?: string // Optional uid to be used for the componet inside the nest, defaults to crypto.randomUID
}) {
    const uid = options.componentUID ?? window.crypto['randomUUID']();

    const currentNest = new Map();

    function addToNest(key: string, value: any) {
        currentNest.set(key, value);
        if (masterNest) {
            addToMasterNest(key, value);
        }
    }

    function removeFromNest(key: string) {
        if (currentNest.has(key)) {
            currentNest.delete(key);
        }
        if (masterNest) {
            removeFromMasterNest(key);
        }
    }

    provide(options.injectinoKey, {
        currentNest,
        addToNest,
        removeFromNest
    });

    const { currentNest: masterNest, addToNest: addToMasterNest, removeFromNest: removeFromMasterNest } = inject(options.injectinoKey, { currentNest: false });

    onMounted(() => {
        if (options.nestedValue !== undefined) {
            addToNest(uid, options.nestedValue);

            if (masterNest) {
                addToMasterNest(uid, options.nestedValue);
            }
        }
    });

    onBeforeUnmount(() => {
        if (masterNest) {
            removeFromMasterNest(uid);
        }
    });

    function add(value: any) {
        addToNest(uid, value);

        // if (masterNest) {
        //     addToMasterNest(uid, value);
        // }
    }

    function remove() {
        removeFromNest(uid);

        // if (masterNest) {
        //     removeFromMasterNest(uid);
        // }
    }

    return { currentNest, masterNest, add, remove };
}